import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Typography,
  TextField,
  MenuItem
} from "@mui/material";

const UNITS = {
  DISTANCE: ["m", "cm", "mm"],
  TIME: ["h", "m", "s"],
  VOLUME: ["l", "cl", "ml"],
  OTHER: ["other"]
}

const ResultTemplateData = ({
  block,
  sectionIndex,
  blockIndex,
  updateParams,
  blockResult = null
}) => {

  const findUnit = () => {
    if (blockResult) {
      return blockResult.params[0].detail.unit;
    }
    return UNITS[block.params[0].value.type][0]
  }

  const { t } = useTranslation("patient_evaluation");
  const [unit, setUnit] = useState(findUnit());
  const [answers, setAnswers] = useState(
    Array.from({ length: block.params.length }, (_, index) => {
      if (blockResult) {
        return blockResult.params[index].value;
      }
      return ""
    })
  )

  const SELECT_VALUES = {
    DISTANCE: UNITS.DISTANCE.map((item) => ({
      text: t(`evaluation.activities.data.options.distance.${item}`),
      value: item
    })),
    TIME: UNITS.TIME.map((item) => ({
      text: t(`evaluation.activities.data.options.time.${item}`),
      value: item
    })),
    VOLUME: UNITS.VOLUME.map((item) => ({
      text: t(`evaluation.activities.data.options.volume.${item}`),
      value: item
    })),
    OTHER: [{
      text: t("evaluation.activities.data.options.other.other"),
      value: "other"
    }]
  }

  const updateUnit = (value) => {
    setUnit(value);
    const newResults = block?.params.map((param, index) => {
      return {
        _id: param._id,
        value: answers[index],
        detail: {
          unit: value
        }
      }
    });
    updateParams(newResults, sectionIndex, blockIndex);
  };

  const updateValue = (value, index) => {
    const answersCopy = [...answers];
    answersCopy[index] = value;
    const newResults = block?.params.map((param, index) => {
      return {
        _id: param._id,
        value: answersCopy[index],
        detail: {
          unit,
        }
      }
    });
    setAnswers(answersCopy);
    updateParams(newResults, sectionIndex, blockIndex);
  }

  return (
    <Grid container direction="column" rowSpacing={2} paddingTop={2}>
      <Grid>
        <TextField
          disabled={!!blockResult}
          select
          variant="outlined"
          value={unit}
          onChange={(e) => updateUnit(e.target.value)}
        >
          {SELECT_VALUES[block.params[0].value.type].map((item, idx) => {
            return (
              <MenuItem
                key={idx}
                value={item.value}
              >
                {item.text}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid>
        {block.params.map((param, pidx) => (
          <Grid key={pidx} container rowSpacing={2} sx={{ alignItems: "center" }}>
            <Grid>
              <Typography variant="body1" color="gray">
                {pidx + 1}
              </Typography>
            </Grid>
            <Grid>
              <TextField
                disabled={!!blockResult}
                variant="standard"
                value={answers[pidx]}
                placeholder={
                  t(`evaluation.activities.data.placeholders.${block.params[0].value.type.toLowerCase()}`)
                }
                onChange={(e) => updateValue(e.target.value, pidx)}
                fullWidth={true}
                sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
              />
            </Grid>
            <Grid>
              <Typography variant="body1" color="gray">
                {t(`evaluation.activities.data.options.${block.params[0].value.type.toLowerCase()}.${unit}`)
                  .toLowerCase()}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle" color="red">
                {param.required ? "*" : ""}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default ResultTemplateData;