import React, { useState } from "react"
import {
  Unstable_Grid2 as Grid,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";

const ResultTemplateSingle = ({
  block,
  sectionIndex,
  blockIndex,
  updateParams,
  blockResult = null
}) => {
  const getResultValue = () => {
    const paramId = blockResult.params.find((item) => item.value)._id;
    return block.params.find((item) => item._id === paramId).value;
  }

  const [result, setResult] = useState(blockResult ? getResultValue() : null);

  const updateResult = (value) => {
    setResult(+value);
    const newResults = block?.params.map((param) => {
      return {
        _id: param._id,
        value: param.value === +value
      }
    });
    updateParams(newResults, sectionIndex, blockIndex);
  }

  return (
    <Grid paddingTop={1}>
      <RadioGroup
        value={result}
        onChange={(e) => updateResult(e.target.value)}
        sx={{ '& .MuiFormControlLabel-root': { margin: 0, padding: 0 } }}
      >
        {block.params.map((param) => (<FormControlLabel
          disabled={!!blockResult}
          key={param._id}
          value={param.value}
          control={<Radio />}
          label={param.text}
        />))}
      </RadioGroup>
    </Grid>
  )
}

export default ResultTemplateSingle;