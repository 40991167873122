import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  TextField,
  Typography,
  MenuItem
} from "@mui/material";
import styled from "styled-components";

const NumberInput = styled(TextField)(() => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input": {
    fontWeight: "bold",
  },
}));

const DataTemplate = ({
  params,
  updateParams,
  readOnly = false
}) => {
  const { t } = useTranslation("evaluations_new");
  const [paramType, setParamType] = useState(params[0].value.type);
  const [requiredResponses, setRequiredResponses] = useState(
    readOnly ? params.filter((item) => item.required).length : 1
  );

  const DATA_TYPES = [
    { text: t("template.activity.data.distance"), value: "DISTANCE" },
    { text: t("template.activity.data.time"), value: "TIME" },
    { text: t("template.activity.data.volume"), value: "VOLUME" },
    { text: t("template.activity.data.other"), value: "OTHER" }
  ]

  const updateParamType = (newType) => {
    const paramsCopy = [...params];
    paramsCopy.forEach((param) => {
      param.value.type = newType;
    })
    updateParams(paramsCopy);
    setParamType(newType);
  }

  const changeMaximumParams = (amount) => {
    const paramsCopy = [...params];
    if (+amount === 0) {
      return;
    } else if (+amount > paramsCopy.length) {
      const newParams = Array.from({ length: +amount - paramsCopy.length }, () => ({
        value: { type: paramType },
        required: false
      }));
      paramsCopy.push(...newParams);
      updateParams(paramsCopy);
    } else if (+amount < paramsCopy.length) {
      if (+amount < requiredResponses) {
        setRequiredResponses(+amount);
      }
      updateParams(paramsCopy.slice(0, +amount));
    }
  };

  const changeRequiredParams = (amount) => {
    const paramsCopy = [...params];
    paramsCopy.forEach((param, index) => {
      param.required = index < amount;
    })
    setRequiredResponses(+amount > params.length ? params.length : amount);
    updateParams(paramsCopy);
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Grid>
          <TextField
            disabled={readOnly}
            select
            variant="outlined"
            value={paramType}
            onChange={(e) => updateParamType(e.target.value)}
            InputProps={{ style: { fontWeight: "bold" } }}
            size="small"
          >
            {DATA_TYPES.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={item.value}
                >
                  {item.text}
                </MenuItem>
              )
            })}
          </TextField>
        </Grid>
        <Grid>
          <Typography variant="body2">
            {t("template.activity.data.responseMaximum")}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <NumberInput
            type="number"
            disabled={readOnly}
            variant="outlined"
            value={params.length}
            onChange={(e) => changeMaximumParams(e.target.value)}
            size="small"
          />
        </Grid>
        <Grid>
          <Typography variant="body2">
            {t("template.activity.data.responseRequired")}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <NumberInput
            type="number"
            disabled={readOnly}
            variant="outlined"
            value={requiredResponses}
            onChange={(e) => changeRequiredParams(e.target.value)}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
      >
        {params.map((param, index) => (
          <Grid
            key={index}
            xs={4}
          >
            <TextField
              disabled
              variant="standard"
              label={t(`template.activity.data.units.value`)
                + ` ${index + 1} `
                + t(`template.activity.data.units.${paramType.toLowerCase()}`)
                + `${param.required ? "*" : ""}`}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default DataTemplate;