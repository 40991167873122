import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Box,
  TextField,
  IconButton,
  Typography
} from "@mui/material";
import {
  RadioButtonUnchecked,
  CreateOutlined,
  ClearOutlined
} from "@mui/icons-material";
import styled from "styled-components";

const NumberInput = styled(TextField)(() => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const SelectionTemplate = ({
  params,
  updateParams,
  readOnly = false
}) => {
  const { t } = useTranslation("evaluations_new");
  const [isEditingScore, setIsEditingScore] = useState(false);


  // When updating a title, validity is based on its own validity and whether the score is also valid
  const setOptionTitle = (index, event) => {
    const paramsCopy = [...params];
    paramsCopy[index].text = event.target.value;
    paramsCopy[index].valid = event.target.validity.valid && !isNaN(paramsCopy[index].value);
    updateParams(paramsCopy);
  }

  // When updating a score, validity is guaranteed on its own since it can't be empty, and only depends on text
  const setOptionScore = (index, event) => {
    const paramsCopy = [...params];
    paramsCopy[index].value = !event.target.value ? 0 : +event.target.value;
    paramsCopy[index].valid = event.target.value ? true : !!paramsCopy[index].text;
    updateParams(paramsCopy);
  }

  const addNewOption = () => {
    const paramsCopy = [...params];
    paramsCopy.push({
      text: "",
      value: paramsCopy.length + 1,
      valid: false,
    })
    updateParams(paramsCopy);
  }

  const deleteOption = (index) => {
    const paramsCopy = [...params];
    paramsCopy.splice(index, 1);
    updateParams(paramsCopy);
  }

  return (
    <>
      {!isEditingScore ? (<Grid container direction="column">
        {params.map((item, index) => (
          <Grid
            key={index}
            container
            padding={1}
            spacing={1}>
            <Grid
              container
              xs={1}
              sx={{ alignItems: "center", }}
            >
              <RadioButtonUnchecked />
            </Grid>
            <Grid xs={11}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  required
                  disabled={readOnly}
                  error={!item.text}
                  variant="standard"
                  label={t("template.activity.select.option") + ` ${index + 1}`}
                  value={item.text}
                  onChange={(e) => setOptionTitle(index, e)}
                  fullWidth={true}
                  helperText={!item.text && t("template.activity.validation.empty")}
                />
                {(!readOnly && params.length > 1) && (<IconButton onClick={() => deleteOption(index)}>
                  <ClearOutlined />
                </IconButton>)}
              </Box>
            </Grid>
          </Grid>
        ))}
        {!readOnly && (<Grid
          container
          padding={1}
          spacing={1}>
          <Grid
            container
            xs={1}
            sx={{ alignItems: "center", }}
          >
            <RadioButtonUnchecked />
          </Grid>
          <Grid container xs={11} spacing={1}>
            <Grid>
              <Typography
                variant="subtitle"
                sx={{ textDecoration: 'underline', color: "gray" }}
                onClick={() => addNewOption()}
              >
                {t("template.activity.select.optionAdd")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>)}
      </Grid>) : (<Grid container spacing={1} direction="column">
        <Grid container columnSpacing={2}>
          <Grid sm={10}>
            <Typography variant="subtitle">
              {t("template.activity.select.options")}
            </Typography>
          </Grid>
          <Grid sm={2}>
            <Typography variant="subtitle">
              {t("template.activity.select.scores")}
            </Typography>
          </Grid>
        </Grid>
        {params.map((item, index) => (
          <Grid
            key={index}
            container
            paddingX={1}
            columnSpacing={2}
            sx={{ alignItems: "center" }}
          >
            <Grid xs={10}>
              <TextField
                disabled
                variant="standard"
                label={t("template.activity.select.option") + ` ${index + 1}`}
                value={item.text}
                fullWidth
              />
            </Grid>
            <Grid xs={2}>
              <NumberInput
                type="number"
                disabled={readOnly}
                variant="outlined"
                value={item.value}
                onChange={(e) => setOptionScore(index, e)}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        ))}
      </Grid >)}
      <Grid
        container
        padding={2}
        sx={{
          alignItems: "center"
        }}
      >
        <Grid>
          <CreateOutlined />
        </Grid>
        <Grid>
          <Typography
            variant="subtitle"
            sx={{ textDecoration: 'underline' }}
            onClick={() => { setIsEditingScore(!isEditingScore) }}
          >
            {isEditingScore
              ? t(`template.activity.select.${readOnly ? "view" : "edit"}Questions`)
              : t(`template.activity.select.${readOnly ? "view" : "edit"}Scores`)}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default SelectionTemplate;