import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Paper,
  Divider,
  TextField,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Switch,
  Tooltip
} from "@mui/material";
import {
  DeleteOutlined,
  ContentCopyOutlined,
  RadioButtonCheckedOutlined,
  FormatAlignLeftOutlined,
  CheckBoxOutlined,
  LooksOneOutlined,
  LinearScaleOutlined,
  LayersOutlined,
  StorageOutlined,
  ArrowDropDown
} from "@mui/icons-material"
import SelectionTemplate from "./SelectionTemplate";
import RangeTemplate from "./RangeTemplate";
import DataTemplate from "./DataTemplate";

const SELECT_VALUES = (t) => ({
  SINGLE: {
    text: t("template.activity.types.single"),
    icon: <RadioButtonCheckedOutlined />,
    defaultParams: [{ text: "", value: 1, valid: false }],
    template: SelectionTemplate,
  },
  DATA: {
    text: t("template.activity.types.data"),
    icon: <StorageOutlined />,
    defaultParams: [{ value: { type: "DISTANCE" }, required: true }],
    template: DataTemplate,
  },
  NUMBER: {
    text: t("template.activity.types.number"),
    icon: <LooksOneOutlined />,
    defaultParams: [{ value: { min: 0, max: 100 }, valid: true }],
    template: null,
  },
  MULTIPLE: {
    text: t("template.activity.types.multiple"),
    icon: <CheckBoxOutlined />,
    defaultParams: [{ text: "", value: 1, valid: false }],
    template: SelectionTemplate
  },
  RANGE: {
    text: t("template.activity.types.range"),
    icon: <LayersOutlined />,
    defaultParams: [{ value: { min: 1, max: 5, step: 1 } }],
    template: RangeTemplate,
  },
  SLIDER: {
    text: t("template.activity.types.slider"),
    icon: <LinearScaleOutlined />,
    defaultParams: [{ value: { min: 1, max: 5, step: 1 } }],
    template: RangeTemplate,
  },
  TEXTQUESTION: {
    text: t("template.activity.types.text"),
    icon: <FormatAlignLeftOutlined />,
    defaultParams: [{ value: 50, valid: true }],
    template: null,
  },
})

const BlockTemplate = ({
  block,
  updateBlock,
  sectionIndex,
  blockIndex,
  removeBlock,
  duplicateBlock,
  canDelete = false,
  readOnly = false,
}) => {
  const { t } = useTranslation("evaluations_new");
  const [anchorEl, setAnchorEl] = useState(null);

  const [type, setType] = useState(block?.type || "TEXTQUESTION");
  const [title, setTitle] = useState(block?.title || "");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState(block?.description || "");
  const [required, setRequired] = useState(block?.required || false);
  // const [media, setMedia] = useState(block?.media || []);
  const [params, setParams] = useState(block?.params || SELECT_VALUES(t).TEXTQUESTION.defaultParams);
  const valid = useMemo(() => {
    if (params.length) {
      return !titleError && params.every((param) => param.valid)
    }
    return !titleError
  }, [titleError, params]);

  const selectedValue = useMemo(() => {
    return SELECT_VALUES(t)[type]
  }, [type]);

  useEffect(() => {
    updateBlock(type, title, description, required, params, valid, sectionIndex, blockIndex);
  }, [type, title, description, required, params, valid])

  const setDefaultParams = (newType) => {
    setParams(SELECT_VALUES(t)[newType].defaultParams);
    setType(newType);
    setAnchorEl(null);
  };

  const updateTitle = (event) => {
    setTitle(event.target.value);
    if (event.target.validity.valid) {
      setTitleError(false);
    } else {
      setTitleError(true);
    }
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        square={false}
      >
        <Grid container paddingX={5} paddingY={3} spacing={1}>
          <Grid md={8} sm={12}>
            <TextField
              required
              disabled={readOnly}
              variant="standard"
              placeholder={t("template.activity.title")}
              value={title}
              onChange={(e) => updateTitle(e)}
              fullWidth={true}
              error={titleError}
              helperText={titleError && t("template.activity.validation.empty")}
              sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
            />
          </Grid>
          <Grid md={4} sm={12}>
            <Button
              disabled={readOnly}
              variant="contained"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              fullWidth
              sx={{ justifyContent: "space-between" }}
            >
              <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
                {selectedValue.icon}
                {selectedValue.text}
              </span>
              <ArrowDropDown />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              elevation={10}
              slotProps={{ paper: { sx: { width: anchorEl ? anchorEl.offsetWidth : "auto" } } }}
            >
              {Object.keys(SELECT_VALUES(t)).reduce((acc, option, index) => {
                acc.push(
                  <MenuItem
                    key={option}
                    onClick={() => setDefaultParams(option)}>
                    {SELECT_VALUES(t)[option].icon} {SELECT_VALUES(t)[option].text}
                  </MenuItem>
                );
                if (index === 1) {
                  acc.push(<Divider key={`divider-${index}`} />);
                }
                return acc;
              }, [])}
            </Menu>
          </Grid>
        </Grid>
        <Grid paddingX={5}>
          <TextField
            disabled={readOnly}
            variant="standard"
            placeholder={t("template.activity.description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth={true}
            sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
          />
        </Grid>
        <Grid paddingX={5} paddingY={3}>
          {SELECT_VALUES(t)[type].template && (
            <selectedValue.template
              params={params}
              updateParams={setParams}
              readOnly={readOnly}
            />
          )}
        </Grid>
        <Grid paddingX={2}>
          <Divider />
        </Grid>
        <Grid
          container
          padding={2}
          spacing={1}
          sx={{
            justifyContent: "flex-end",
            alignItems: "center"
          }}>
          <Grid>
            <Tooltip title={t("template.activity.duplicate")}>
              <span>
                <IconButton
                  size="large"
                  disabled={readOnly}
                  onClick={() => duplicateBlock(sectionIndex, blockIndex)}
                >
                  <ContentCopyOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid>
            <Tooltip title={t("template.activity.delete")}>
              <span>
                <IconButton
                  size="large"
                  disabled={readOnly || !canDelete}
                  onClick={() => removeBlock(sectionIndex, blockIndex)}
                >
                  <DeleteOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid>
            <Typography variant="subtitle" color={readOnly ? "gray" : "black"}>
              {t("template.activity.required")}
            </Typography>
          </Grid>
          <Grid>
            <Switch
              disabled={readOnly}
              checked={required}
              onClick={() => setRequired(!required)}
            />
          </Grid>
        </Grid>
      </Paper >
    </Grid >
  )
}

export default BlockTemplate;