import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "../../store";
import { debounce } from 'lodash';

import * as S from "./ClinicalRecords.styles";

import API from "../../services/api";

import {
  Pagination,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Box,
  Tabs,
  Grid,
  Container,
  TextField,
  InputAdornment
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

import {
  FilterSidebar,
  ModalNewOrEditUserComponent,
  PatientCard,
  RecordCard,
  RecordTable,
} from "./components";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { DatePickerComponent } from "../../components/DatePickerv2";

import { utils } from "@amir04lm26/react-modern-calendar-date-picker";
import {
  FORM_INITIAL_VALUE,
  handleDateFilter,
  handleDatePickerChange,
} from "./clinicalRecords.helpers";

import { rutValidation } from "../../lib/validation.helpers";
import { getUserId } from "../../lib/router";
import { RolBlockedScreen } from "../../components/RolBlockedScreen";

import { Virtuoso } from "react-virtuoso";

const api = new API();

const TherapistTherapist = (props) => {
  const history = useHistory();
  let { id } = useParams();

  const { t } = useTranslation([
    "registers",
    "create_patient",
    "modes",
    "sessionStudio",
  ]);

  const [patients, setPatients] = useState([]);

  const [, setRegisters] = useState([]);
  const [, setTrainings] = useState([]);
  const [idActual, setIdActual] = useState("");
  const [dateStart, setDateStart] = useState({ from: null, to: null });
  const [, setActivePatients] = useState(true);
  const [, setActiveData] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageRecord, setPageRecord] = useState(1);
  const [limitRecord] = useState(4);
  const [totalPagesRecord, setTotalPagesRecord] = useState(0);
  const [pageUsers, setPageUsers] = useState(1);
  const [limitUsers] = useState(150);
  const [totalPagesUsers, setTotalPagesUsers] = useState(0);

  const [filter, setFilter] = useState("");
  const [dateFiltered, setDateFiltered] = useState({
    from: null,
    to: null,
  });

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [dateOtherRecordsFiltered, setDateOtherRecordsFiltered] = useState({
    from: null,
    to: null,
  });

  const [minimumDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();

  const [specialityFilter, setSpecialityFilter] = useState("");

  const debouncedGetPatients = useCallback(
    debounce((value, page) => {
      handleGetPatients(value, page);
    }, 1000),
    []
  );

  const debouncedGetEvolution = useCallback(
    debounce((id, page, value, dateFilter, speciality) => {
      handleGetEvolution(id, page, value, dateFilter, speciality);
    }, 1000),
    []
  );

  const handleFilterUsers = (value) => {
    setFilter(value);
    setPageUsers(1);
    debouncedGetPatients(value, 1);
  };

  const handleGetPatients = (filterValue = "", pageValue = 1) => {

    setPacientLoading(true);
    api
      .getAllUserPatientsPaginated(pageValue, limitUsers, filterValue)
      .then((res) => {
        setPatients(res.data);
        setActivePatients(false);
        setTotalPagesUsers(res.totalPages);

        if (id) {
          getRegisterPatient(id, dateStart, page, limit);
          setIdActual(id);
          scrollElement(id);
          setTrainings([]);
        } else {

          if (res.data[0]._id) {
            getRegisterPatient(res.data[0]._id, dateStart, page, limit);
            setIdActual(res.data[0]._id);
            scrollElement(res.data[0]._id);
            setTrainings([]);
          }

        }

      })
      .finally(() => setPacientLoading(false))
      .catch(() => setRegisters([]));
  };

  const handleGetRecords = (id, date, pageNum, limit) => {
    const controller = new AbortController();
    const { signal } = controller;
    setRecordsLoading(true);
    setIdActual(id);
    setActiveData(true);
    if (date === undefined || date.from == null) date = defaultDate();

    const fromDate = new Date(date.from.year, date.from.month - 1, date.from.day);
    const toDate = new Date(date.to.year, date.to.month - 1, date.to.day + 1);
    // crea un nuevo objeto con las fechas en formato ISO
    const dateISO = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    axios
      .post(`/user/records/${id}/${pageNum}/${limit}`, dateISO, { signal })
      .then(({ data }) => {
        setRecords(data.data);
        setRecordActive(data.data[0]);
        setTotalPagesRecord(data.totalPages);
      })
      .finally(() => setRecordsLoading(false))
      .catch((err) => {
        setRecords([]);
        setRecordActive("");
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
  };

  const handleGetEvolution = (id, page, searchQuery = "", dateFilter = "", specialityFilter = "") => {
    const controller = new AbortController();
    const { signal } = controller;
    setHistoryLoading(true);
    setActiveData(true);
    const dateTo = dateFilter.to;
    const dateFrom = dateFilter.from;
    axios
      .get(`/user/evolution/${id}/${page}/${limit}`, {
        signal,
        params: {
          search: searchQuery,
          from: dateFrom,
          to: dateTo,
          speciality: specialityFilter,
        }
      })
      .then(({ data }) => {
        setHistorical(data.data);
        setTotalPages(data.totalPages);
      })
      .finally(() => setHistoryLoading(false))
      .catch((err) => {
        setHistorical([]);
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setPage(1);
    debouncedGetEvolution(idActual, 1, value, dateFiltered, specialityFilter);
  };

  useEffect(() => {
    initDate();
    handleGetPatients();

    return () => {
      setPatients([]);
      setPacientLoading(false);
      setIdActual("");
      setTrainings([]);
      setRegisters([]);
      setDateStart({ from: null, to: null });
    };
  }, []);

  const initDate = () => {
    setDateStart(defaultDate());
  };

  const scrollElement = (id) => {
    document.querySelector("#id-" + id).scrollIntoView({
      behavior: "smooth",
    });
  };

  const subtractMonths = (numOfMonths, date = new Date()) => {
    date.setMonth(date.getMonth() - numOfMonths);
    return date;
  };

  const defaultDate = () => {
    let from = subtractMonths(6);
    from.setSeconds(0);
    from.setMinutes(0);
    from.setHours(0);
    return {
      from: {
        year: from.getFullYear(),
        month: from.getMonth() + 1,
        day: from.getDate(),
      },
      to: utils().getToday(),
    };
  };

  // -> Estado almacenando registros de evolución e históricos.
  const [records, setRecords] = useState([]);
  const [historical, setHistorical] = useState([]);
  const [recordActive, setRecordActive] = useState("");

  const [pacientLoading, setPacientLoading] = useState(true);
  const [, setRecordsLoading] = useState(true);
  const [historyLoading, setHistoryLoading] = useState(false);

  const getRegisterPatient = useCallback((id, date, pageNum, limit) => {
    const controller = new AbortController();
    const { signal } = controller;

    setHistoryLoading(true);

    setIdActual(id);
    setActiveData(true);
    if (date === undefined || date.from == null) date = defaultDate();

    const fromDate = new Date(date.from.year, date.from.month - 1, date.from.day);
    const toDate = new Date(date.to.year, date.to.month - 1, date.to.day + 1);
    // crea un nuevo objeto con las fechas en formato ISO
    const dateISO = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    axios
      .post(`/user/records/${id}/${pageNum}/${limit}`, dateISO, { signal })
      .then(({ data }) => {
        setRecords(data.data);
        setRecordActive(data.data[0]);
        setTotalPagesRecord(data.totalPages);
      })
      .finally(() => setRecordsLoading(false))
      .catch((err) => {
        setRecords([]);
        setRecordActive("");
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });

    axios
      .get(`/user/evolution/${id}/${page}/${limit}`, { signal })
      .then(({ data }) => {
        setHistorical(data.data);
        setTotalPages(data.totalPages);
      })
      .finally(() => setHistoryLoading(false))
      .catch((err) => {
        setHistorical([]);
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [showSidebar, setShowSidebar] = useState(false);
  const handleShowSidebar = () => setShowSidebar(true);
  const handleCloseSidebar = () => setShowSidebar(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: FORM_INITIAL_VALUE,
  });

  const [country, setCountry] = useState("");
  const [commune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [stateAddress, setStateAddress] = useState();

  const getDataAddress = (data) => {
    setCountry(data.country);
    setCommune(data.state);
    setRegion(data.region);
    setAddress(`${data.address} ${data.number}`);
    setStateAddress(data.addressComplete);
  };

  const onSubmit = async (dataForm) => {
    if (!rutValidation(dataForm.nationality, dataForm.rut)) {
      props.notify({
        type: "error",
        title: t("create_patient:notify.invalid_id"),
        text: t("create_patient:notify.invalid_id"),
      });
      return;
    }

    if (patients.some((patient) => patient.rut === dataForm.rut)) {
      props.notify({
        type: "error",
        title: t("create_patient:notify.invalid_id"),
        text: t("create_patient:notify.exits"),
      });
      return;
    }

    if (country.trim() === "") {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.country"),
      });
      return;
    }

    if (region.trim() === "") {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.state"),
      });
      return;
    }

    if (address.trim() === "") {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.address"),
      });
      return;
    }

    const form = new FormData();
    form.append("nationality", dataForm.nationality);
    form.append("rut", dataForm.nationality === 'CL' ? dataForm.rut.trim() : dataForm.rut);
    form.append("name", dataForm.name);
    form.append("lastname", dataForm.lastname);
    form.append("email", dataForm.email);
    form.append("phone", dataForm.phone);
    form.append("injury_years", dataForm.injury_years);
    form.append("genre", dataForm.genre);
    form.append("birthdate", dataForm.birthdate.replaceAll("/", "-"));
    form.append("country", country);
    form.append("region", region);
    form.append("commune", commune);
    form.append("rol", dataForm.rol);
    form.append("address", address);
    form.append("other_address", dataForm.other_address);
    form.append("innsurance_company", dataForm.innsurance_company);
    form.append("clinic", dataForm.clinic);
    form.append("treating_doctor", dataForm.treating_doctor);
    form.append("years_injury", dataForm.years_injury);
    form.append("reference", dataForm.reference);

    try {
      await api.createPatien(form, isSubmitting);

      props.notify({
        type: "success",
        title: t("create_patient:notify.success"),
        text: t("create_patient:notify.success"),
      });

      // * Reset Data
      reset();
      setCountry("");
      setCommune("");
      setRegion("");
      setAddress("");
      setStateAddress(undefined);

      handleGetPatients();
      handleCloseModal();
    } catch ({ data }) {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: `${t("create_patient:notify.err")}. ${data.err.err}`,
      });
    }
  };

  const clearFilterRecordTable = () => {
    setDateOtherRecordsFiltered({
      from: null,
      to: null,
    });
    setFromDate(undefined);
    setToDate(undefined);
    setPageRecord(1);
    handleGetRecords(idActual, { from: null, to: null }, 1, limitRecord);
  };

  const handleFilterChange = (speciality, dateFilter) => {
    setPage(1);
    handleGetEvolution(idActual, page, searchValue, dateFilter, speciality);
  };

  const sidebarFilter = {
    title: (
      <>
        <TuneIcon /> {t("filters")}
      </>
    ),
    content: (
      <FilterSidebar
        dateFiltered={dateFiltered}
        setDateFiltered={setDateFiltered}
        setSpecialityFilter={setSpecialityFilter}
        handleCloseSidebar={handleCloseSidebar}
        onFilterChange={handleFilterChange}
      />
    ),
  };

  const Header = () => null;
  const Footer = () => <div style={{ height: "50px", width: "100%" }}></div>;

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <CardLayoutPlatform
            loading={pacientLoading}
            title={t("titlePatients")}
            footer={
              <div style={{ overflowX: "hidden", display: "flex", justifyContent: "flex-end", width: "100%", backgroundColor: "white" }}>
                {!pacientLoading ? (
                  <>
                    <Pagination
                      count={totalPagesUsers}
                      onChange={(_, newPageUsers) => {
                        setPageUsers(newPageUsers);
                        handleGetPatients(filter, newPageUsers);
                      }}
                      sx={{ margin: 'auto' }}
                      page={pageUsers}
                      shape="rounded"
                    />
                    <Fab size="medium" color="primary" onClick={handleShowModal}>
                      <Add />
                    </Fab>
                  </>
                ) : null}
              </div>
            }
            noPaddingBottom
            padding={"0"}
            header={
              <div style={{ marginTop: "14px", padding: "0 14px 10px 14px" }}>
                <TextField
                  fullWidth
                  placeholder={t("search")}
                  value={filter}
                  onChange={(event) => handleFilterUsers(event.target.value)}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#ecf2ff',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      },
                    },
                  }}
                />
              </div>
            }
            overflowVirtuoso="true"
          >
            <Virtuoso
              style={{
                height: "calc(100vh - 249px)",
                padding: "0 0 200px 0",
              }}
              data={patients}
              components={{ Header, Footer }}
              itemContent={(index, patient) => {
                return (
                  <PatientCard
                    t={t}
                    key={patient._id}
                    idActual={idActual}
                    data={patient}
                    historyLoading={historyLoading}
                    onClick={() => {
                      getRegisterPatient(patient._id, dateStart, page, limit);
                      setActiveTab(0);
                    }}
                    handleGetPatients={handleGetPatients}
                  />
                );
              }}
            />
          </CardLayoutPlatform>
        </Grid>
        <Grid item xs={7}>
          <CardLayoutPlatform
            loading={historyLoading || pacientLoading}
            title={t("titleRegisterHistory")}
            showSidebar={showSidebar}
            handleClose={handleCloseModal}
            handleCloseSidebar={handleCloseSidebar}
            sidebarTitle={sidebarFilter.title}
            sidebarContent={sidebarFilter.content}
            footer={
              activeTab === 0 && getUserId().rol !== "admin" ? (
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", backgroundColor: "white" }}>
                  {!historyLoading && !pacientLoading ? (
                    <>
                      <Pagination
                        count={totalPages}
                        onChange={(_, newPage) => {
                          setPage(newPage);
                          handleGetEvolution(idActual, newPage, searchValue, dateFiltered, specialityFilter);
                        }}
                        sx={{ margin: 'auto' }}
                        page={page}
                        shape="rounded"
                      />
                      <Fab
                        size="medium"
                        color="primary"
                        onClick={() => {
                          history.push({
                            pathname: `/patient-evolution/${idActual}`
                          });

                        }}
                      >
                        <Add />
                      </Fab>
                    </>
                  ) : null}
                </div>
              ) : null
            }
          >
            {getUserId().rol !== "admin" ? (
              <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    sx={{
                      '& .MuiTab-root': {
                        textTransform: 'none',
                        minWidth: 'auto',
                        padding: '12px 16px',
                      },
                      '& .Mui-selected': {
                        color: 'primary.main',
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'primary.main',
                      }
                    }}
                  >
                    <Tab label={t("evolutions")} />
                    <Tab label={t("another_records")} />
                  </Tabs>
                </Box>
                <S.HeaderStyled>
                  {activeTab === 0 && (
                    <>
                      <TextField
                        fullWidth
                        placeholder={t("search")}
                        onChange={(event) => handleSearchChange(event.target.value)}
                        value={searchValue}
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'primary.main',
                            },
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        startIcon={<TuneIcon />}
                        onClick={handleShowSidebar}
                      >
                        {t("filters")}
                      </Button>
                    </>
                  )}
                </S.HeaderStyled>

                <TabPanel value={activeTab} index={0}>
                  {historical.map((r) => (
                    <RecordCard key={r._id} data={r} />
                  ))}

                  {historical.length === 0 && (
                    <S.CenteredScreen>
                      <p>{t("no_records_evolution")}</p>
                    </S.CenteredScreen>
                  )}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <S.DateFilterHeader>
                    <p>{t("filter_by_date_range")}</p>
                    <DatePickerComponent
                      height="36px"
                      placeholder={t("from")}
                      range={false}
                      value={fromDate}
                      onChange={(value) => {
                        setFromDate(value);
                        handleDatePickerChange(value, toDate, setMinimumDate, setDateStart);
                      }}
                      maximumDate={maximumDate}
                    />
                    <DatePickerComponent
                      height="36px"
                      placeholder={t("to")}
                      range={false}
                      value={toDate}
                      onChange={(value) => {
                        setToDate(value);
                        handleDatePickerChange(fromDate, value, setMaximumDate, setDateStart);
                      }}
                      minimumDate={minimumDate ? minimumDate : undefined}
                    />
                    <Button
                      type="ghost"
                      label={t("clear")}
                      width="90px"
                      onClick={clearFilterRecordTable}
                    />
                  </S.DateFilterHeader>

                  {records
                    .filter((item) =>
                      handleDateFilter(item, dateOtherRecordsFiltered)
                    )
                    .map((record, i) => (
                      <RecordTable
                        key={`record-${i}`}
                        record={record}
                        recordActive={recordActive}
                        setRecordActive={setRecordActive}
                        t={t}
                      />
                    ))}

                  {records.length === 0 && (
                    <S.CenteredScreen>
                      <p>{t("no_records")}</p>
                    </S.CenteredScreen>
                  )}
                  <Pagination
                    count={totalPagesRecord}
                    onChange={(_, newPageRecord) => {
                      setPageRecord(newPageRecord);
                      handleGetRecords(idActual, dateStart, newPageRecord, limitRecord);
                    }}
                    page={pageRecord}
                    shape="rounded"
                  />
                </TabPanel>
              </>
            ) : (
              <RolBlockedScreen t={t} />
            )}
          </CardLayoutPlatform>
        </Grid>
      </Grid>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: 2,
            maxWidth: '600px',
            width: '100%'
          }
        }}
      >
        <DialogTitle
          sx={{
            padding: '16px 24px',
            fontSize: '1.25rem',
            fontWeight: 500
          }}
        >
          {t("create_new_patient")}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '20px 24px',
            overflow: 'visible' // Important for dropdowns
          }}
        >
          <ModalNewOrEditUserComponent
            isEditing={false}
            watch={watch}
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            control={control}
            stateAddress={stateAddress}
            setStateAddress={setStateAddress}
            getDataAddress={getDataAddress}
            t={t}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            sx={{
              color: 'text.secondary',
              borderColor: 'text.secondary'
            }}
          >
            {t("sessionStudio:cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark'
              }
            }}
          >
            {t("sessionStudio:save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default connect(TherapistTherapist);
