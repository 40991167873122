import React from "react";
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography
} from "@mui/material";
import { Close } from "@mui/icons-material"

const FullScreenModal = ({
  open,
  handleClose,
  title,
  submitButton,
  Content,
  onSubmit,
  canSubmit = true
}) => {
  const saveData = async () => {
    await onSubmit();
    handleClose(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => handleClose(false)}
      PaperProps={{ sx: { backgroundColor: "modalBackground.main" } }}
    >
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose(false)}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {canSubmit && <Button
            autoFocus
            color="inherit"
            onClick={saveData}
          >
            {submitButton}
          </Button>}
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ mt: 4, overflow: "auto" }}>
        {Content}
      </DialogContent>
    </Dialog>
  )
}

export default FullScreenModal;