import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Paper,
  useTheme
} from "@mui/material";
import BlockTemplate from "./BlockTemplate";
import { cloneDeep } from "lodash";

const EvaluationView = ({
  template,
  updateTemplate,
  readOnly
}) => {
  const theme = useTheme();

  const defaultBlock = {
    _id: v4(),
    type: "TEXTQUESTION",
    title: "",
    description: "",
    required: true,
    media: [],
    params: {
      value: 50
    },
    valid: false,
  }
  const { t } = useTranslation("evaluations_new");
  const [sections, setSections] = useState(
    template.sections.length ? template.sections : [
      {
        _id: v4(),
        title: "",
        description: "",
        blocks: [defaultBlock]
      }
    ]);

  useEffect(() => {
    const templateCopy = { ...template };
    templateCopy.sections = sections;
    updateTemplate(templateCopy);
  }, [sections]);

  const updateSection = (key, value, index) => {
    const sectionsCopy = [...sections];
    sectionsCopy[index][key] = value;
    setSections(sectionsCopy);
  }

  const addBlock = (sectionIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks.push(defaultBlock);
    setSections(sectionsCopy);
  }

  const updateBlock = (type, title, description, required, params, valid, sectionIndex, blockIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks[blockIndex] = {
      _id: sections[sectionIndex].blocks[blockIndex]._id,
      type,
      title,
      description,
      required,
      media: [],
      params,
      valid
    }
    setSections(sectionsCopy);
  }

  const deleteBlock = (sectionIndex, blockIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks.splice(blockIndex, 1);
    setSections(sectionsCopy);
  }

  const duplicateBlock = (sectionIndex, blockIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks.push({
      ...cloneDeep(sectionsCopy[sectionIndex].blocks[blockIndex]),
      _id: v4(),
    });
    setSections(sectionsCopy);
  }

  return (<Grid
    container
    sx={{
      justifyContent: "center",
      alignItems: "center",
    }}>
    <Grid
      container
      direction={"column"}
      padding={2}
      spacing={2}
      xl={5}
      md={7}
      xs={12}
    >
      {sections.map((section, s_idx) => (
        <Grid key={section._id}>
          <Grid
            container
            direction={"column"}
            spacing={3}
          >
            <Grid>
              <Paper elevation={3} square={false} sx={{ borderTop: `4px solid ${theme.palette.primary.main}` }}>
                <Grid container direction="column" paddingX={5} paddingY={3} rowSpacing={3}>
                  <Grid>
                    <TextField
                      required
                      disabled={readOnly}
                      variant="standard"
                      placeholder={t("template.name")}
                      value={section.title}
                      onChange={(e) => updateSection("title", e.target.value, s_idx)}
                      fullWidth
                      sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      disabled={readOnly}
                      variant="standard"
                      placeholder={t("template.description")}
                      value={section.description}
                      onChange={(e) => updateSection("description", e.target.value, s_idx)}
                      fullWidth
                      sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {section.blocks.map((block, b_idx) => (
              <Grid key={block._id}>
                <BlockTemplate
                  block={block}
                  updateBlock={updateBlock}
                  sectionIndex={s_idx}
                  blockIndex={b_idx}
                  canDelete={section.blocks.length > 1}
                  removeBlock={deleteBlock}
                  duplicateBlock={duplicateBlock}
                  readOnly={readOnly}
                />
              </Grid>
            ))}
          </Grid>
          {!readOnly && (<Grid
            container
            padding={2}
            sx={{
              justifyContent: "flex-end"
            }}>
            <Grid>
              <Button
                variant="contained"
                onClick={() => addBlock(s_idx)}
              >
                {t("template.add")}
              </Button>
            </Grid>
          </Grid>)}
        </Grid>
      ))}
    </Grid >
  </Grid>)
}

export default EvaluationView;