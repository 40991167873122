import React from "react"
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ResultTemplateSectionHeader = ({
  section,
}) => {
  const { t } = useTranslation("patient_evaluation");
  const theme = useTheme();

  return (
    <Card
      elevation={3}
      sx={{ borderTop: `4px solid ${theme.palette.primary.main}` }}
    >
      <CardContent>
        <Grid container direction="column" padding={2} spacing={1}>
          <Grid container sx={{ alignItems: "center" }}>
            <Typography variant="h4">
              {section.title}
            </Typography>
          </Grid>
          {section.description && (<Grid>
            <Typography variant="subtitle1">
              {section.description}
            </Typography>
          </Grid>)}
          <Grid>
            <Divider />
          </Grid>
          <Grid>
            <Typography variant="body2" color="red">
              {t("evaluation.activities.section.required")}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ResultTemplateSectionHeader;