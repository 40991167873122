import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import {
  Unstable_Grid2 as Grid,
  Box,
  Divider,
  Typography,
  Button,
  Pagination,
  TextField,
  InputAdornment,
  ThemeProvider
} from "@mui/material";
import {
  ErrorOutline,
  CheckCircleOutline,
  Search
} from "@mui/icons-material"
import { getUserId } from "../../lib/router";
import LoadingDialog from "../../components/LoadingDialog";
import GenericDialog from "../../components/GenericDialog";
import FullScreenModal from "../../components/FullScreenModal";
import EvaluationResultModal from "./components/EvaluationResultModal";
import PatientList from "./components/PatientList";
import ResultList from "./components/ResultList";
import theme from "../../styles/main.styles";

const PatientEvaluation = () => {
  const { t } = useTranslation("patient_evaluation");

  const limit = 100; // This could eventually be changed to a dynamic value

  const [search, setSearch] = useState("");
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(0);
  const [output, setOutput] = useState({
    evaluationTemplateId: null,
    sections: null
  });
  const [patientResults, setPatientResults] = useState([]);

  const [chosenResult, setChosenResult] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [resultsCurrentPage, setResultsCurrentPage] = useState(1);
  const [resultsTotalPages, setResultsTotalPages] = useState(1);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(t("patient.loading"))
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("patient.error"));
  const [successDialog, setSuccessDialog] = useState(false);

  const hasMountedRef = useRef(false);

  const openDialog = () => {
    setChosenResult(null);
    setDialogOpen(true);
  }

  const closeDialog = () => { setDialogOpen(false) }

  const fetchPatientList = async (page) => {
    setLoadingMessage(t("patient.loading"));
    setLoadingPatients(true);
    setLoadingResults(true);
    let response = { data: { data: null } };
    try {
      response = await axios.get(`user/getallpatients/${page}/${limit}?search=${search}`);
      setPatients(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setErrorMessage(t("patient.error"))
      setErrorDialog(true);
    } finally {
      setLoadingPatients(false);
      // Explicitly returning data is for getting patient results right after this request,
      // since state can't be relied on due to being asynchronous
      return response.data.data;
    }
  }

  const fetchPatientResults = async (currentPatients, patient = selectedPatient) => {
    setLoadingMessage(t("patient.loading"));
    setLoadingResults(true);
    try {
      const response = await axios.get(`evaluation_result/patient/${currentPatients[patient]._id}`);
      setPatientResults(response.data.evaluations);
      setResultsTotalPages(response.data.pages);
    } catch (error) {
      setErrorMessage(t("patient.errorResults"))
      setErrorDialog(true);
    } finally {
      setLoadingResults(false);
    }
  }

  const fetchPatientsAndResultsOnPageUpdate = async (page) => {
    setCurrentPage(page);
    const result = await fetchPatientList(page);
    setSelectedPatient(0);
    await fetchPatientResults(result, 0);
  }

  const fetchResultsOnPageUpdate = async (page) => {
    setResultsCurrentPage(page);
    await fetchPatientResults(patients, 0);
  }

  const fetchResultsAfterNewResultCreated = async () => {
    setSuccessDialog(false);
    await fetchPatientResults(patients, selectedPatient);
  }

  const fetchResultsAfterPatientSelected = async (index) => {
    setSelectedPatient(index);
    await fetchPatientResults(patients, index)
  }

  const debouncedSearch = debounce((page) => {
    fetchPatientsAndResultsOnPageUpdate(page)
  }, 500)

  useEffect(() => {
    fetchPatientsAndResultsOnPageUpdate(1);
  }, [])

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      return;
    }
    debouncedSearch(1);
    return () => {
      debouncedSearch.cancel()
    }
  }, [search])

  const viewResults = (index) => {
    setChosenResult(index);
    setDialogOpen(true);
  }

  const updateOutput = (values) => {
    setOutput({
      evaluationTemplateId: values.evaluationTemplateId || output.evaluationTemplateId,
      sections: values.sections
    })
  }

  const saveResults = async () => {
    setLoadingMessage(t("results.loading"))
    setLoadingSave(true);
    try {
      await axios.post("evaluation_result", {
        ...output,
        therapistId: getUserId().userId,
        patientId: patients[selectedPatient]._id,
        // Temporarily setting the current date until a date field is added
        evaluatedAt: new Date().toISOString().split('T')[0],
      });
      setSuccessDialog(true)
    } catch (error) {
      setErrorMessage(t("results.error"))
      setErrorDialog(true);
    } finally {
      setLoadingSave(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container padding={2} spacing={1}>
          <Grid container xl={3} md={4} xs={12} direction="column" spacing={1}>
            <Grid container columnSpacing={1} sx={{ justifyContent: "space-between" }}>
              <Grid>
                <Typography variant="h6" gutterBottom>
                  {t("patient.title")}
                </Typography>
              </Grid>
              <Grid container paddingX={2}>
                <TextField
                  variant="outlined"
                  placeholder={t("patient.search")}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            </Grid>
            <Grid>
              <Divider />
            </Grid>
            <Grid container direction="column" spacing={1} sx={{ maxHeight: "70vh", overflow: "auto" }}>
              <PatientList
                patients={patients}
                selectedPatient={selectedPatient}
                updateSelectedPatient={fetchResultsAfterPatientSelected}
                loading={loadingPatients}
              />
            </Grid>
            <Grid
              container
              paddingY={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Grid>
                <Pagination
                  disabled={loadingPatients}
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, page) => fetchPatientsAndResultsOnPageUpdate(page)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xl={9} md={8} xs={12} direction="column" spacing={1}>
            <Grid container sx={{ justifyContent: "flex-end" }}>
              <Grid>
                <Button
                  disabled={patients.length === 0 || loadingPatients || loadingResults}
                  variant="contained"
                  onClick={openDialog}
                >
                  {t("history.add")}
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <Divider />
            </Grid>
            <Grid>
              <ResultList
                results={patientResults}
                loading={loadingResults}
                chooseResult={viewResults}
              />
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Grid>
                <Pagination
                  disabled={loadingResults}
                  count={resultsTotalPages}
                  page={resultsCurrentPage}
                  onChange={(e, page) => fetchResultsOnPageUpdate(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {(patients.length > 0) && <FullScreenModal
        open={dialogOpen}
        handleClose={closeDialog}
        title={chosenResult === null ? t("evaluation.title") : t("evaluation.view")}
        submitButton={t("evaluation.save")}
        onSubmit={saveResults}
        canSubmit={chosenResult === null}
        Content={
          <EvaluationResultModal
            patient={patients[selectedPatient]}
            results={output}
            setResults={updateOutput}
            patientResult={patientResults[chosenResult] || null}
          />
        }
      />}
      <LoadingDialog
        open={loadingSave}
        message={loadingMessage}
      />
      <GenericDialog
        open={successDialog}
        handleClose={fetchResultsAfterNewResultCreated}
        icon={<CheckCircleOutline />}
        message={t("results.success")}
      />
      <GenericDialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        icon={<ErrorOutline />}
        message={errorMessage}
      />
    </ThemeProvider>
  )
}

export default PatientEvaluation;