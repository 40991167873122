import React from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
  Typography
} from "@mui/material";
import {
  ArrowForward,
} from "@mui/icons-material"

const ResultList = ({ results, loading, chooseResult }) => {
  const { t } = useTranslation("patient_evaluation");

  const tableHeaders = [
    t("history.results.name"),
    t("history.results.therapy"),
    t("history.results.tags"),
    t("history.results.evaluator"),
    t("history.results.date"),
    "",
  ]

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return date.split("T")[0]
  }

  return (
    <>
      {loading ? <Grid
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid>
          <CircularProgress />
        </Grid>
        <Grid>
          {t("patient.loadingResults")}
        </Grid>
      </Grid> : <TableContainer sx={{ maxHeight: "70vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{ textAlign: "left !important", position: "sticky", top: 0, zIndex: 2 }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((evaluation, idx) => (
              <TableRow
                key={evaluation._id}
              >
                <TableCell sx={{ textAlign: "left !important" }}>
                  {evaluation.evaluationTemplateId}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {/* TODO: Add therapy */}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {/* TODO: Add tags */}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {/* TODO: Add therapist */}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {formatDate(evaluation.createdAt)}
                </TableCell>
                <TableCell>
                  <Grid container sx={{ justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <Grid onClick={() => chooseResult(idx)}>
                      <Typography
                        variant="body2"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {t("history.results.view")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton>
                        <ArrowForward />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  )
}

export default ResultList;